var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.editMode)?_c('div',{staticClass:"columns"},[_c('b-notification',{attrs:{"type":"is-info is-light","aria-close-label":"Close notification"}},[_vm._v(" Campurile notate cu * sunt obligatoriu de completat. ")])],1):_vm._e(),_c('ValidationObserver',{ref:"form"},[(_vm.editMode)?_c('div',{staticClass:"columns"},[(!_vm.hasRegistraturaAccess())?_c('div',[_c('div',{staticClass:"column is-narrow"},[_c('div',[_c('b-field',{attrs:{"label":"Nr. Inreg. intern"}},[_c('label',[_vm._v(_vm._s(_vm.registryEntry.internalRegistrationNumber)+"/"+_vm._s(_vm._f("formatDate")(_vm.registryEntry.internalRegistrationDate)))])])],1)]),_c('div',{staticClass:"column is-narrow"},[_c('b-field',{attrs:{"label":"Departament"}},[_c('label',[_vm._v(_vm._s(_vm.departmentName))])])],1)]):_c('div',[_c('div',{staticClass:"column is-narrow"},[_c('ValidationProvider',{attrs:{"name":"Departament","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Departament *","message":errors[0]}},[_c('b-dropdown',{attrs:{"multiple":"","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-right":"menu-down"}},[_vm._v(" Selectat ("+_vm._s(_vm.departmentName)+") ")])]},proxy:true}],null,true),model:{value:(_vm.departmentName),callback:function ($$v) {_vm.departmentName=$$v},expression:"departmentName"}},_vm._l((_vm.departments),function(dep){return _c('b-dropdown-item',{key:dep.id,attrs:{"value":dep.name,"aria-role":"listitem"}},[_c('span',[_vm._v(_vm._s(dep.name))])])}),1)],1)]}}],null,false,3227078750)})],1),_c('div',{staticClass:"column is narrow"},[_c('b-field',{attrs:{"label":"Nr. Inreg. intern"}},[_c('label',[_vm._v(_vm._s(_vm.registryEntry.internalRegistrationNumber)+"/"+_vm._s(_vm._f("formatDate")(_vm.registryEntry.internalRegistrationDate)))])])],1)])]):_c('div',{staticClass:"columns"},[(_vm.hasRegistraturaAccess())?_c('div',[_c('div',{staticClass:"column is-narrow"},[_c('ValidationProvider',{attrs:{"name":"Departament","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Departament *","message":errors[0]}},[_c('b-dropdown',{attrs:{"multiple":"","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-right":"menu-down"}},[_vm._v(" Selectat ("+_vm._s(_vm.departmentName)+") ")])]},proxy:true}],null,true),model:{value:(_vm.departmentName),callback:function ($$v) {_vm.departmentName=$$v},expression:"departmentName"}},_vm._l((_vm.departments),function(dep){return _c('b-dropdown-item',{key:dep.id,attrs:{"value":dep.name,"aria-role":"listitem"}},[_c('span',[_vm._v(_vm._s(dep.name))])])}),1)],1)]}}],null,false,3227078750)})],1),_c('div',{staticClass:"column is-narrow"},[_c('ValidationProvider',{attrs:{"name":"Numere rezervate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Numere rezervate","message":errors[0]}},[_c('b-select',{attrs:{"placeholder":"Selecteaza numarul intern de inregistrare","icon":"account"},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.selectedRegistrationNumber),callback:function ($$v) {_vm.selectedRegistrationNumber=$$v},expression:"selectedRegistrationNumber"}},_vm._l((_vm.reservedNumbers),function(reservedNumber){return _c('option',{key:reservedNumber.id,domProps:{"value":reservedNumber}},[_vm._v(" "+_vm._s(reservedNumber.number)+"/"+_vm._s(_vm._f("formatDate")(reservedNumber.registrationDate))+" ")])}),0)],1)]}}],null,false,1117802522)})],1)]):_c('div',[_c('div',{staticClass:"column is-narrow"},[_c('b-field',{attrs:{"label":"Departament"}},[_c('label',[_vm._v(_vm._s(_vm.departmentName))])])],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-narrow"},[_c('ValidationProvider',{attrs:{"name":"Tip document"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0],"label":"Tip document"}},[_c('div',{staticClass:"block"},[_c('b-radio',{attrs:{"name":"name","native-value":"Intrare","disabled":_vm.isDisabled},model:{value:(_vm.registryEntry.type),callback:function ($$v) {_vm.$set(_vm.registryEntry, "type", $$v)},expression:"registryEntry.type"}},[_vm._v(" Intrare ")]),_c('b-radio',{attrs:{"name":"name","native-value":"Iesire","disabled":_vm.isDisabled},model:{value:(_vm.registryEntry.type),callback:function ($$v) {_vm.$set(_vm.registryEntry, "type", $$v)},expression:"registryEntry.type"}},[_vm._v(" Iesire ")])],1)])]}}])})],1),_c('div',{staticClass:"column is-narrow"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Status","message":errors[0]}},[_c('b-dropdown',{attrs:{"aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"type":"is-primary","icon-right":"menu-down"}},[_vm._v(" "+_vm._s(_vm.registryEntry.status)+" ")])]},proxy:true}],null,true),model:{value:(_vm.registryEntry.status),callback:function ($$v) {_vm.$set(_vm.registryEntry, "status", $$v)},expression:"registryEntry.status"}},[_c('b-dropdown-item',{attrs:{"value":"Rezolvat","aria-role":"listitem"}},[_c('span',[_vm._v("Rezolvat")])]),_c('b-dropdown-item',{attrs:{"value":"În lucru","aria-role":"listitem"}},[_c('span',[_vm._v("În lucru")])])],1)],1)]}}])})],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"Emitent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Emitent","message":errors[0]}},[_c('b-input',{attrs:{"disabled":_vm.isDisabled},model:{value:(_vm.registryEntry.issuer),callback:function ($$v) {_vm.$set(_vm.registryEntry, "issuer", $$v)},expression:"registryEntry.issuer"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"Nr. Inreg. extern"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Nr. Inreg. extern","message":errors[0]}},[_c('b-input',{attrs:{"disabled":_vm.isDisabled},model:{value:(_vm.registryEntry.externalRegistrationNumber),callback:function ($$v) {_vm.$set(_vm.registryEntry, "externalRegistrationNumber", $$v)},expression:"registryEntry.externalRegistrationNumber"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"Data Inreg. extern"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Data Inreg. extern","message":errors[0]}},[_c('b-datepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","disabled":_vm.isDisabled},model:{value:(_vm.registryEntry.externalRegistrationDate),callback:function ($$v) {_vm.$set(_vm.registryEntry, "externalRegistrationDate", $$v)},expression:"registryEntry.externalRegistrationDate"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"Continut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Continut *","message":errors[0]}},[_c('b-input',{attrs:{"type":"text","required":"","disabled":_vm.isDisabled},model:{value:(_vm.registryEntry.description),callback:function ($$v) {_vm.$set(_vm.registryEntry, "description", $$v)},expression:"registryEntry.description"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"Data expiedere"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Data expediere","message":errors[0]}},[_c('b-datepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","disabled":_vm.isDisabled},model:{value:(_vm.registryEntry.dispatchDate),callback:function ($$v) {_vm.$set(_vm.registryEntry, "dispatchDate", $$v)},expression:"registryEntry.dispatchDate"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"Destinatar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Destinatar","message":errors[0],"disabled":_vm.isDisabled}},[_c('b-input',{attrs:{"disabled":_vm.isDisabled},model:{value:(_vm.registryEntry.destination),callback:function ($$v) {_vm.$set(_vm.registryEntry, "destination", $$v)},expression:"registryEntry.destination"}})],1)]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_c('ValidationProvider',{attrs:{"name":"Nr.zile pentru solutionare"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Nr.zile solutionare.","message":errors[0]}},[_c('b-input',{attrs:{"step":"1","min":"0","max":"10000000","type":"number","disabled":_vm.isDisabled},on:{"input":_vm.changeNumberOfDaysToRespond},model:{value:(_vm.registryEntry.numberOfDaysToRespond),callback:function ($$v) {_vm.$set(_vm.registryEntry, "numberOfDaysToRespond", $$v)},expression:"registryEntry.numberOfDaysToRespond"}})],1)]}}])})],1),_c('div',{staticClass:"column is-2"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Data limita solutionare")]),_c('div',{staticClass:"control"},[_c('b-datepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","unselectable-days-of-week":[0, 6],"disabled":_vm.isDisabled},on:{"input":function($event){return _vm.deadlineSelected()}},model:{value:(_vm.registryEntry.deadline),callback:function ($$v) {_vm.$set(_vm.registryEntry, "deadline", $$v)},expression:"registryEntry.deadline"}})],1)])]),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"name":"Solutionare (pe scurt)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : '',"label":"Solutionare (pe scurt)","message":errors[0]}},[_c('b-input',{model:{value:(_vm.registryEntry.responseDescription),callback:function ($$v) {_vm.$set(_vm.registryEntry, "responseDescription", $$v)},expression:"registryEntry.responseDescription"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Data solutionare")]),_c('div',{staticClass:"control"},[_c('b-datepicker',{attrs:{"date-parser":_vm.parser,"locale":"ro","unselectable-days-of-week":[0, 6]},model:{value:(_vm.registryEntry.responseDate),callback:function ($$v) {_vm.$set(_vm.registryEntry, "responseDate", $$v)},expression:"registryEntry.responseDate"}})],1)])])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"grouped":""}},[_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"},on:{"click":_vm.handleAddRegistryEntry}},[_vm._v("Salvare")])],1),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-danger is-outlined"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Anulare")])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="columns" v-if="!editMode">
      <b-notification
        type="is-info is-light"
        aria-close-label="Close notification"
      >
        Campurile notate cu * sunt obligatoriu de completat.
      </b-notification>
    </div>
    <ValidationObserver ref="form">
      <div class="columns" v-if="editMode">
        <div v-if="!hasRegistraturaAccess()">
          <div class="column is-narrow">
            <div>
              <b-field label="Nr. Inreg. intern">
                <label
                  >{{ registryEntry.internalRegistrationNumber }}/{{
                    registryEntry.internalRegistrationDate | formatDate
                  }}</label
                >
              </b-field>
            </div>
          </div>
          <div class="column is-narrow">
            <b-field label="Departament">
              <label>{{ departmentName }}</label>
            </b-field>
          </div>
        </div>
        <div v-else>
          <div class="column is-narrow">
            <ValidationProvider
              name="Departament"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                :type="errors[0] ? 'is-danger' : ''"
                label="Departament *"
                :message="errors[0]"
              >
                <b-dropdown v-model="departmentName" multiple aria-role="list">
                  <template #trigger>
                    <b-button type="is-primary" icon-right="menu-down">
                      Selectat ({{ departmentName }})
                    </b-button>
                  </template>
                  <b-dropdown-item
                    v-for="dep in departments"
                    :value="dep.name"
                    :key="dep.id"
                    aria-role="listitem"
                  >
                    <span>{{ dep.name }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is narrow">
            <b-field label="Nr. Inreg. intern">
              <label
                >{{ registryEntry.internalRegistrationNumber }}/{{
                  registryEntry.internalRegistrationDate | formatDate
                }}</label
              >
            </b-field>
          </div>
        </div>
      </div>
      <div class="columns" v-else>
        <div v-if="hasRegistraturaAccess()">
          <div class="column is-narrow">
            <ValidationProvider
              name="Departament"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                :type="errors[0] ? 'is-danger' : ''"
                label="Departament *"
                :message="errors[0]"
              >
                <b-dropdown v-model="departmentName" multiple aria-role="list">
                  <template #trigger>
                    <b-button type="is-primary" icon-right="menu-down">
                      Selectat ({{ departmentName }})
                    </b-button>
                  </template>
                  <b-dropdown-item
                    v-for="dep in departments"
                    :value="dep.name"
                    :key="dep.id"
                    aria-role="listitem"
                  >
                    <span>{{ dep.name }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-narrow">
            <ValidationProvider name="Numere rezervate" v-slot="{ errors }">
              <b-field
                :type="errors[0] ? 'is-danger' : ''"
                label="Numere rezervate"
                :message="errors[0]"
              >
                <b-select
                  placeholder="Selecteaza numarul intern de inregistrare"
                  icon="account"
                  v-model="selectedRegistrationNumber"
                  @focus="onFocus()"
                >
                  <option
                    v-for="reservedNumber in reservedNumbers"
                    :value="reservedNumber"
                    :key="reservedNumber.id"
                  >
                    {{ reservedNumber.number }}/{{
                      reservedNumber.registrationDate | formatDate
                    }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
        <div v-else>
          <div class="column is-narrow">
            <b-field label="Departament">
              <label>{{ departmentName }}</label>
            </b-field>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-narrow">
          <ValidationProvider name="Tip document" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              :message="errors[0]"
              label="Tip document"
            >
              <div class="block">
                <b-radio
                  v-model="registryEntry.type"
                  name="name"
                  native-value="Intrare"
                  :disabled="isDisabled"
                >
                  Intrare
                </b-radio>
                <b-radio
                  v-model="registryEntry.type"
                  name="name"
                  native-value="Iesire"
                  :disabled="isDisabled"
                >
                  Iesire
                </b-radio>
              </div>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-narrow">
          <div class="field">
            <ValidationProvider
              name="Status"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                :type="errors[0] ? 'is-danger' : ''"
                label="Status"
                :message="errors[0]"
              >
                <b-dropdown v-model="registryEntry.status" aria-role="list">
                  <template #trigger>
                    <b-button type="is-primary" icon-right="menu-down">
                      {{ registryEntry.status }}
                    </b-button>
                  </template>

                  <b-dropdown-item value="Rezolvat" aria-role="listitem">
                    <span>Rezolvat</span>
                  </b-dropdown-item>

                  <b-dropdown-item value="În lucru" aria-role="listitem">
                    <span>În lucru</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <ValidationProvider name="Emitent" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Emitent"
              :message="errors[0]"
            >
              <b-input v-model="registryEntry.issuer" :disabled="isDisabled" />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-4">
          <ValidationProvider name="Nr. Inreg. extern" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Nr. Inreg. extern"
              :message="errors[0]"
            >
              <b-input
                :disabled="isDisabled"
                v-model="registryEntry.externalRegistrationNumber"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-4">
          <ValidationProvider name="Data Inreg. extern" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Data Inreg. extern"
              :message="errors[0]"
            >
              <b-datepicker
                :date-parser="parser"
                v-model="registryEntry.externalRegistrationDate"
                locale="ro"
                :disabled="isDisabled"
              >
              </b-datepicker>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="columns">
        <div class="column is-4">
          <ValidationProvider
            name="Continut"
            rules="required"
            v-slot="{ errors }"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Continut *"
              :message="errors[0]"
            >
              <b-input
                v-model="registryEntry.description"
                type="text"
                required
                :disabled="isDisabled"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-4">
          <ValidationProvider name="Data expiedere" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Data expediere"
              :message="errors[0]"
            >
              <b-datepicker
                :date-parser="parser"
                v-model="registryEntry.dispatchDate"
                locale="ro"
                :disabled="isDisabled"
              >
              </b-datepicker>
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-4">
          <ValidationProvider name="Destinatar" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Destinatar"
              :message="errors[0]"
              :disabled="isDisabled"
            >
              <b-input
                :disabled="isDisabled"
                v-model="registryEntry.destination"
              />
            </b-field>
          </ValidationProvider>
        </div>
      </div>
      <div class="columns">
        <div class="column is-2">
          <ValidationProvider
            name="Nr.zile pentru solutionare"
            v-slot="{ errors }"
          >
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Nr.zile solutionare."
              :message="errors[0]"
            >
              <b-input
                v-model="registryEntry.numberOfDaysToRespond"
                step="1"
                min="0"
                max="10000000"
                type="number"
                :disabled="isDisabled"
                @input="changeNumberOfDaysToRespond"
              />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-2">
          <div class="field">
            <label class="label">Data limita solutionare</label>
            <div class="control">
              <b-datepicker
                :date-parser="parser"
                v-model="registryEntry.deadline"
                @input="deadlineSelected()"
                locale="ro"
                :unselectable-days-of-week="[0, 6]"
                :disabled="isDisabled"
              >
              </b-datepicker>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <ValidationProvider name="Solutionare (pe scurt)" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Solutionare (pe scurt)"
              :message="errors[0]"
            >
              <b-input v-model="registryEntry.responseDescription" />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Data solutionare</label>
            <div class="control">
              <b-datepicker
                :date-parser="parser"
                v-model="registryEntry.responseDate"
                locale="ro"
                :unselectable-days-of-week="[0, 6]"
              >
              </b-datepicker>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
    <div class="columns">
      <div class="column">
        <b-field grouped>
          <div class="control">
            <b-button
              type="is-primary"
              native-type="submit"
              @click="handleAddRegistryEntry"
              >Salvare</b-button
            >
          </div>
          <div class="control">
            <b-button type="is-danger is-outlined" @click="cancel()"
              >Anulare</b-button
            >
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { rawObject } from "@/helpers/utils";
import { pathOr } from "ramda";
import RegistryEntries from "@/services/registryEntries.service";
import RegistriesService from "@/services/registry.service";
import { accessMixin } from "../../mixins/accessMixin";
import moment from "moment";

export default {
  name: "RegistryEntriesAdd",
  mixins: [accessMixin],
  computed: {
    isDisabled() {
      if (this.editMode && !this.hasAdminToRegistry()) {
        return "disabled";
      } else {
        return null;
      }
    },
  },
  methods: {
    changeNumberOfDaysToRespond(value) {
      this.registryEntry.deadline = new Date(
        moment(this.registryEntry.internalRegistrationDate, "DD-MM-YYYY").add(
          value,
          "days"
        )
      );
    },
    deadlineSelected() {
      var a = moment(this.registryEntry.deadline).startOf("day");
      var b = moment(this.registryEntry.internalRegistrationDate).startOf(
        "day"
      );

      this.registryEntry.numberOfDaysToRespond = moment(a)
        .startOf("day")
        .diff(moment(b).startOf("day"), "days");

},
    onFocus() {
      this.getReservedNumbers();
    },
    parser(d) {
      return new Date(Date.parse(d));
    },
    handleAddRegistryEntry() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.departments.length == 1) {
          this.registryEntry.department = this.departments[0].name;
        } else {
          this.registryEntry.department = this.departmentName.join(";");
        }
        this.registryEntry.username = this.$store.state.userName;
        this.registryEntry.registryId = this.registryId;
        if (this.registryEntry.externalRegistrationDate) {
          this.registryEntry.externalRegistrationDate = new Date(
            this.registryEntry.externalRegistrationDate.getTime() -
              this.registryEntry.externalRegistrationDate.getTimezoneOffset() *
                60000
          ).toJSON();
        }
        if (this.registryEntry.deadline) {
          this.registryEntry.deadline = new Date(
            this.registryEntry.deadline.getTime() -
              this.registryEntry.deadline.getTimezoneOffset() * 60000
          ).toJSON();
        }

        if (this.registryEntry.responseDate) {
          this.registryEntry.responseDate = new Date(
            this.registryEntry.responseDate.getTime() -
              this.registryEntry.responseDate.getTimezoneOffset() * 60000
          ).toJSON();
        }

        if (this.registryEntry.internalRegistrationDate) {
          this.registryEntry.internalRegistrationDate = new Date(
            this.registryEntry.internalRegistrationDate.getTime() -
              this.registryEntry.internalRegistrationDate.getTimezoneOffset() *
                60000
          ).toJSON();
        }
        if (this.registryEntry.dispatchDate) {
          this.registryEntry.dispatchDate = new Date(
            this.registryEntry.dispatchDate.getTime() -
              this.registryEntry.dispatchDate.getTimezoneOffset() * 60000
          ).toJSON();
        }

        if (this.editMode) {
          // this.registryEntry.internalRegistrationNumber = this.selectedRegistrationNumber;
          this.$emit("handleEditRegistryEntry", rawObject(this.registryEntry));
        } else {
          this.registryEntry.internalRegistrationNumber = this.selectedRegistrationNumber.number;
          this.$emit("handleAddRegistryEntry", rawObject(this.registryEntry));
        }
      });
    },
    async getDepartments() {
      const { getAllDepartments } = RegistryEntries;
      getAllDepartments().then((response) => {
        const { data } = response;
        this.departments = data;
        if (this.departments.length == 1) {
          this.departmentName = this.departments[0].name;
        }
      });
    },
    async getReservedNumbers() {
      this.reservedNumbers = [];
      const { getReservedNumbers } = RegistriesService;
      getReservedNumbers(this.registryId).then((response) => {
        this.reservedNumbers = response.data;
      });
    },
    async getRegistryEntry(id) {
      const { handleGetRegistryEntry } = RegistryEntries;
      const response = await handleGetRegistryEntry(id);
      const { data } = response;
      return data;
    },
    async getRegistryEntries(registryId) {
      const { getRegistryEntries } = RegistriesService;
      const response = await getRegistryEntries(registryId);
      const { data } = response;
      return data;
    },
    cancel() {
      this.$router.push({ path: "/RegistryEntries" });
    },
  },
  data() {
    return {
      editMode: false,
      id: 0,
      selectedRegistrationNumber: 0,
      departmentName: [],
      reservedNumbers: [],
      departments: [],
      registryId: 0,
      registryEntry: {
        internalRegistrationNumber: 0,
        internalRegistrationDate: null,
        externalRegistrationNumber: null,
        externalRegistrationDate: null,
        registryId: 0,
        issuer: null,
        description: null,
        department: null,
        dispatchDate: null,
        destination: null,
        status: "În lucru",
        type: null,
        numberOfDaysToRespond: 0,
        deadline: null,
        responseDescription: null,
        responseDate: null,
      },
    };
  },
  created() {
    const registryId = parseInt(
      pathOr(null, ["params", "registryId"], this?.$route)
    );

    this.registryId = registryId;
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    this.id = id;

    this.getDepartments();
    this.getReservedNumbers();

    if (this.id) {
      this.editMode = true;
      this.getRegistryEntry(id).then((response) => {
        this.registryEntry = response;
        if (this.registryEntry.externalRegistrationDate) {
          this.registryEntry.externalRegistrationDate = new Date(
            this.registryEntry.externalRegistrationDate
          );
        }
        if (this.registryEntry.dispatchDate) {
          this.registryEntry.dispatchDate = new Date(
            this.registryEntry.dispatchDate
          );
        }

        if (this.registryEntry.responseDate) {
          this.registryEntry.responseDate = new Date(
            this.registryEntry.responseDate
          );
        }

        if (this.registryEntry.deadline) {
          this.registryEntry.deadline = new Date(this.registryEntry.deadline);
        }

        if (this.registryEntry.internalRegistrationDate) {
          this.registryEntry.internalRegistrationDate = new Date(
            this.registryEntry.internalRegistrationDate
          );
        }
        this.departmentName = this.registryEntry.department.split(";");

        this.departmentName.forEach(function (item, index) {
          item = item.replace(/^\s+|\s+$/gm, "");
        });
        if (this.departmentName.length === 1) {
          this.departmentName = [this.departmentName[0]];
        }
      });
    }
    this.registryEntry.internalRegistrationDate = new Date();
    this.registryEntry.deadline = new Date(
      moment(this.registryEntry.internalRegistrationDate, "DD-MM-YYYY").add(
        this.registryEntry.numberOfDaysToRespond,
        "days"
      )
    );
  },
};
</script>

<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <card-component
        :title="formCardTitle"
        icon="file-edit"
        class="tile is-child"
      >
        <registry-entries-add
          @handleAddRegistryEntry="handleAddRegistryEntry"
          @handleEditRegistryEntry="handleEditRegistryEntry"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import { pathOr } from "ramda";
import RegistryEntries from "@/services/registryEntries.service";
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import RegistryEntriesAdd from "../../components/registry/registryEntries-add.vue";

export default {
  name: "RegistryEntry",
  components: { TitleBar, CardComponent, RegistryEntriesAdd },
  data() {
    return {
      editMode: false,
    };
  },

  methods: {
    handleAddRegistryEntry(registryEntry) {
      const { addRegistryEntry } = RegistryEntries;
      const response = addRegistryEntry(registryEntry).then((response) => {
        const { data, status } = response;
        if (status == "200") {
          this.$router.push({ path: "/RegistryEntries" });
        } else {
        }
      });
    },
    async handleEditRegistryEntry(registryEntry) {
      const { handleEditRegistryEntry } = RegistryEntries;
      const response = await handleEditRegistryEntry(registryEntry);
      this.$router.push({ path: "/RegistryEntries" });
    },
  },
  computed: {
    titleStack() {
      if (this.editMode) {
        return ["Documente", "Editare document"];
      } else {
        return ["Documente", "Adaugare document"];
      }
    },
    formCardTitle() {
      if (this.editMode) {
        return "Editare document";
      } else {
        return "Adaugare document";
      }
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    if (id) {
      this.editMode = true;
    } else {
      // this.announcement.date = new Date();
    }
  },
};
</script>
